*, *::before, *::after { box-sizing: border-box; }

h1,h2,h3,h4,h5,h6 {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.main-container {
  padding: 1rem;
}

.cards {
  max-width: 1200px;
  margin: 0 auto;
  columns: 6 200px;
  column-gap: 1rem;
}